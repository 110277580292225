  var ready = function() {
    var initialAppCheckbox = document.getElementsByClassName("app_check");
    for(i=0;i < initialAppCheckbox.length; i++) {
	  if (initialAppCheckbox[i].checked) {
	    $(initialAppCheckbox[i]).closest('#roles_container').find('#app_text').show();
	  } else {
  	  $(initialAppCheckbox[i]).closest('#roles_container').find('#app_text').hide();
	  };
	};

    var initialWebCheckbox = document.getElementsByClassName("web_check");
    for(i=0;i < initialWebCheckbox.length; i++) {
	  if (initialWebCheckbox[i].checked) {
	    $(initialWebCheckbox[i]).closest('#roles_container').find('#web_text').show();
	  } else {
  	  $(initialWebCheckbox[i]).closest('#roles_container').find('#web_text').hide();
	  };
	};
	
	var initialFileCheckbox = document.getElementsByClassName("file_check");
    for(i=0;i < initialFileCheckbox.length; i++) {
	  if (initialFileCheckbox[i].checked) {
	    $(initialFileCheckbox[i]).closest('#roles_container').find('#file_text').show();
	  } else {
  	  $(initialFileCheckbox[i]).closest('#roles_container').find('#file_text').hide();
	  };
	};

    addListeners();

    $('form').on('click', '.remove_fields', function(event) {
		console.log('here');
      $(this).prev('input[type=hidden]').val('1');
      $(this).closest('.agent-info').hide();
      event.preventDefault();
    });
    
    $('form').on('click', '.add_fields', function(event) {
      var regexp, time, txtArea;
      time = new Date().getTime();
      regexp = new RegExp($(this).data('id'), 'g');
      $(this).before($(this).data('fields').replace(regexp, time));
      addListeners();
      event.preventDefault();
    });

    function addListeners() {
		var appCheckbox = document.getElementsByClassName("app_check");
		for(i=0;i < appCheckbox.length; i++) {
		  appCheckbox[i].addEventListener("click", function() {
		    if (this.checked) {
		      $(this).closest('#roles_container').find('#app_text').show();
		    } else {
	  		  $(this).closest('#roles_container').find('#app_text').find('textarea').val("");
		      $(this).closest('#roles_container').find('#app_text').hide();
		    };
		  });
		};

		var webCheckbox = document.getElementsByClassName("web_check");
		for(i=0;i < webCheckbox.length; i++) {
		  webCheckbox[i].addEventListener("click", function() {
		    if (this.checked) {
		      $(this).closest('#roles_container').find('#web_text').show();
		    } else {
	  		  $(this).closest('#roles_container').find('#web_text').find('textarea').val("");
		      $(this).closest('#roles_container').find('#web_text').hide();
		    };
		  });
		};
	
		var fileCheckbox = document.getElementsByClassName("file_check");
		for(i=0;i < fileCheckbox.length; i++) {
		  fileCheckbox[i].addEventListener("click", function() {
		    if (this.checked) {
		      $(this).closest('#roles_container').find('#file_text').show();
		    } else {
	  		  $(this).closest('#roles_container').find('#file_text').find('textarea').val("");
		      $(this).closest('#roles_container').find('#file_text').hide();
		    };
		  });
		};
	};
  };

  $(document).on('turbolinks:load', ready);
