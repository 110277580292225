var PlanITDR = PlanITDR || {
    meters: {
        agentNoBackupServer: null,
        agentNoBackupWorkstation: null,
        agentNoSnapshotServer: null,
        agentNoSnapshotWorkstation: null,
        backupApplianceUse: null,
        failedJobsLastHourServer: null,
        failedJobsLastHourWorkstation: null,
        failedJobsLast24: null,
        failedScreenshots: null,
        lostCommunications: null,
        offlineAppliances: null
    },
    meterTimeSpans: localStorage.getItem("meterTimeSpans") ?
        JSON.parse(localStorage.getItem('meterTimeSpans')) :
        {
            agentNoBackupServer: 24,
            agentNoBackupWorkstation: 24,
            agentNoSnapshotServer: 168,
            agentNoSnapshotWorkstation: 168,
        },

    init: function (imagePath, infoImage) {
        MeterSettings.prototype.linkImagePath = imagePath;
        MeterSettings.prototype.infoImagePath = infoImage;


        PlanITDR.meters = {
            agentNoBackupServer: new MeterSettings("meter-no-backup-server", "No Backup (Server)", "no_backup_server", PlanITDR.meterTimeSpans.agentNoBackupServer),
            agentNoBackupWorkstation: new MeterSettings("meter-no-backup-workstation", "No Backup (WS)", "no_backup_workstation", PlanITDR.meterTimeSpans.agentNoBackupWorkstation),
            agentNoSnapshotServer: new MeterSettings("meter-no-snapshot-server", "No Offsite Replication (Server)", "no_snapshot_server", PlanITDR.meterTimeSpans.agentNoSnapshotServer),
            agentNoSnapshotWorkstation: new MeterSettings("meter-no-snapshot-workstation", "No Offsite Replication (WS)", "no_snapshot_workstation", PlanITDR.meterTimeSpans.agentNoSnapshotWorkstation),
            backupApplianceUse: new MeterSettings("meter-in-use", "Appliances > 88% Full", "capacity"),
            failedJobsLastHourServer: new MeterSettings("meter-failed-jobs-1h-server", "Failed Backups in Last Hour (Server)", "failed_job_1h_server"),
            failedJobsLastHourWorkstation: new MeterSettings("meter-failed-jobs-1h-workstation", "Failed Backups in Last Hour (WS)", "failed_job_1h_workstation"),
            failedScreenshots: new MeterSettings("meter-failed-screenshots", "Failed Screenshots", "failed_screenshots"),
            lostCommunications: new MeterSettings("lost-communications","Lost Communications","lost_communications"),
            pausedAgents: new MeterSettings("meter-paused-agents", "Paused Agents", "agent_paused"),
            volumesMissingServer: new MeterSettings("meter-volumes-missing-server", "Volumes Missing (Server)", "volumes_missing_server"),
            volumesMissingWorkstation: new MeterSettings("meter-volumes-missing-workstation", "Volumes Missing (WS)", "volumes_missing_workstation")
        };

        for (var meter in PlanITDR.meters) {
            PlanITDR.getMeterData(PlanITDR.meters[meter]);
        }

        $("#ddlAgentNoBackupServer, #ddlAgentNoBackupWorkstation, #ddlAgentNoSnapshotServer, #ddlAgentNoSnapshotWorkstation")
            .off('change')
            .on('change', PlanITDR.onTimeframeChanged);

        $("#ddlAgentNoBackupServer").val(PlanITDR.meterTimeSpans.agentNoBackupServer);
        $("#ddlAgentNoBackupWorkstation").val(PlanITDR.meterTimeSpans.agentNoBackupWorkstation);
        $("#ddlAgentNoSnapshotServer").val(PlanITDR.meterTimeSpans.agentNoSnapshotServer);
        $("#ddlAgentNoSnapshotWorkstation").val(PlanITDR.meterTimeSpans.agentNoSnapshotWorkstation);

        localStorage.setItem("meterTimeSpans", JSON.stringify(PlanITDR.meterTimeSpans));

        setInterval(function () {
            console.log('reloading...');
            location.reload();
        }, 5 * 60 * 1000);
    },

    getMeterData: function (meter) {
        $.get("/meters.json", meter.params, function (data) {
            meter.update(data);
        });
    },

    onTimeframeChanged: function (e) {
        var hours = parseInt($(e.target).val(), 10);
        var meter = $(e.target).data('meter');
        PlanITDR.meters[meter].params.timeframe = hours;
        PlanITDR.meterTimeSpans[meter] = hours;
        PlanITDR.meters[meter].csvLink = "/meters.csv?m=" + PlanITDR.meters[meter].params.m + "&timeframe=" + hours;
        localStorage.setItem("meterTimeSpans", JSON.stringify((PlanITDR.meterTimeSpans)));
        PlanITDR.getMeterData(PlanITDR.meters[meter]);
    },
    
    viewScreenshot: function(url) {

        //var img = document.createElement("img");
        $("#ss-image").attr('src', url);
        // img.src = url;
        //var container = document.getElementById("ss-image-container");

        // while(container.firstChild){
        //     container.removeChild(container.firstChild);
        // }
        // container.appendChild(img);

        $("#screenshot-modal").on('hidden.bs.modal', function() {
            $("#meter-modal").modal('show');
        });

        $("#meter-modal").modal('hide');

        $('#screenshot-modal').modal('show');
    }
};

var testCount = 1;


function MeterSettings(elementId, title, meterParam, timeFrameParam, thresholds) {
    var self = this;
    this.elementId = elementId;
    this.summaryLink = "https://portal.dattobackup.com/device/";
    this.title = title;
    this.csvLink = "/meters.csv?m=" + meterParam + "&timeframe=" + timeFrameParam;
    this.params = {
        m: meterParam,
        timeframe: timeFrameParam
    };
    this.thresholds = thresholds || {
        error: 5,
        warn: 1
    };
    this.itemCount = 0;
    this.data = [];

    this.update = function (data) {
        var alertClass = "";
        var meter = $("#" + self.elementId);

        self.data = data;
        self.itemCount = self.data.length;

        meter.attr('class', "meter col-6 col-sm-4 col-md-3 col-lg-2");
        meter.find('.meter-detail').attr('class', "meter-detail").off('click');
        meter.find(".meter-count").text(self.itemCount);
        meter.find(".meter-name").text(self.title);

        if (self.itemCount >= self.thresholds.error) {
            alertClass = " text-danger ";
        } else if (self.itemCount >= self.thresholds.warn) {
            alertClass = " text-warning";
        } else {
            alertClass = " text-success ";
        }

        meter.find(".meter-count").addClass(alertClass);

        if (self.itemCount > 0) {
            meter.find(".meter-detail")
                .addClass("hover")
                .off('click')
                .on('click', function (e) {
                    $("#meter-modal-title").text(self.title);
                    $("#meter-modal-csv").attr('href', self.csvLink);
                    self.renderTable();
                    $("#meter-modal").modal('show');
                });
        }
    };

    

    this.renderTable = function () {
        var thVal = "";

        switch (self.params.m) {
            case 'no_snapshot_server': {
                thVal = "Last Offsite"
            }
                break;
	        case 'no_snapshot_workstation': {
	            thVal = "Last Offsite"
	            }
	            break;
            case 'capacity': {
                thVal = "% In Use";
            }
                break;
            case 'failed_screenshots': {
                thVal = "Last Screenshot"
            }
                break;
            case 'lost_communications': {
	            thVal = "Last Seen"
            }
                break;
            case 'volumes_missing_server': {
                thVal = "Volumes Missing"
            }
                break;
            case 'volumes_missing_workstation': {
                thVal = "Volumes Missing"
            }
                break;
            default: {
                thVal = "Last Backup";
            }
        }

        var thead = "<tr><th>Device Name</th>";
        if (self.params.m !== 'capacity' && self.params.m !== 'lost_communications') {
          thead +=  "<th>Agent Name</th>";
        }

        thead += "<th>Connect</th><th>" + thVal + "</th>";

        
        thead += "</tr>";


        var tbody = self.data.length <= 0 ? "<tr><td colspan='4'>No Alerts found</td></tr>" :
            self.data.map(function (dataItem) {

                var serialNumber;
                if (dataItem.serial_num) {
                  serialNumber = dataItem.serial_num.toLowerCase();
                } else {
                  serialNumber = "";
                }

                var row = "<tr class='meter-row'>";
                row += "<td>" + dataItem.dname + "</td>";
                
                if (self.params.m !== 'capacity' && self.params.m !== 'lost_communications') {
                  row += "<td>" + dataItem.aname + "</td>";
                }
                if(dataItem.dsource === "Datto"){
                    row += "<td><a href=" + self.summaryLink + dataItem.serial_num + "/remote-web target='_blank'  ><img src='" + self.linkImagePath + "' alt='External Link' style='height:10px; width:10;' />" + " " + serialNumber + "</a></td>";
                }else{
                    row += "<td>--</td>";
                }

                var tdVal = "";
                var tdClass = "";

                if (dataItem.var_info) {
                    switch (self.params.m) {
                        case 'capacity': {
                            tdVal = Number.parseFloat(dataItem.var_info).toPrecision(3);
                        }
                            break;
                        case 'failed_screenshots': {
                            if(dataItem.dsource === "Datto"){
                                tdVal = '<img src="' + dataItem.var_info + '" style="height: 125px; width:125px;cursor:pointer" onClick="PlanITDR.viewScreenshot(\''+ dataItem.var_info + '\')" alt="Last Successful Screenshot" />';
                            }else{
                                tdVal = "--";
                            }
                        }
                            break;
                        case 'volumes_missing_workstation': 
                        case 'volumes_missing_server': {
                            tdVal = (dataItem.vol_notes) ? dataItem.var_info + '<img class="ws-tooltip" data-toggle="tooltip" title="Volume Notes" data-content="'+ dataItem.vol_notes + '" src="' + self.infoImagePath + '" style="height: 15px; width:15px;cursor:pointer;margin:10px" />' : dataItem.var_info
                            tdClass = "meter-var-col";
                        }
                            break;
                        default: {
                            var utcMoment = moment.utc(dataItem.var_info, 'YYYY-MM-DD HH:mm:ss.S');
							utcMoment.local();
						    tdVal = utcMoment.format('M/D/YYYY h:mm:ss A');	
                            tdClass=' meter-dt-col '
                        }
                    }
                    row += "<td class='" + tdClass + "'>" + tdVal + "</td>";
                } else {
	              row += "<td class='meter-dt-col'>&nbsp;</td>";
                }

                row += "</tr>";

                return row;
            }).join();

        $("#meter-modal-thead").html(thead);
        $("#meter-modal-tbody").html(tbody);
        $(".ws-tooltip").popover({
            trigger: 'hover',
            placement: 'bottom'
        });
    };

    this.update([]);
}

window.PlanITDR = PlanITDR