// Workaround for the issue: https://github.com/turbolinks/turbolinks/issues/179

var jsErrorHasOccurred = false;

window.onerror = function() {
    jsErrorHasOccurred = true;
}

window.addEventListener('popstate', (event) => {
    if (jsErrorHasOccurred == true) {
        var url = document.location.href;

        event.preventDefault();
        window.location.href = url;
    }
});

document.addEventListener("turbolinks:before-visit", function() {
    if (jsErrorHasOccurred == true) {
        var url = event.data.url;

        event.preventDefault();
        window.location.href = url;
    }
})