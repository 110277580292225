require("@rails/ujs").start()
require("turbolinks").start()

window.$ = require("jquery")
window.jQuery = require("jquery")

import 'popper.js/dist/umd/popper.min';
import 'bootstrap';

import 'jquery-ui-bundle';
import 'jquery-ui-timepicker-addon';

import 'jquery-validation';

import moment from 'moment';
window.moment = moment;

import DataTable from 'datatables.net';
import 'datatables.net-buttons';

import './src/agents';
import './src/jquery.formtowizard';
import './src/meters';
import './src/sb-admin-2';
import  './src/post_error_handler'

require("./application.scss")

const images = require.context('../assets/images', true);
const imagePath = (name) => images(name, true);